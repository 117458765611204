@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap");
*
{
    margin: 0;
    padding:0;
    box-sizing: border-box;
    font-family: 'Ubuntu', sans-serif;
}

:root
{
    --blue: #287bff;
    --white: #fff;
    --grey: #f5f5f5;
    --black1: #222;
    --black2: #999;
    --red: #d00
}

body
{
    min-height: 100vh;
    overflow-x: hidden;
}
.container
{
    position: relative;
    width: 100%;
}
.navigation
{
    position: fixed;
    width: 300px;
    height: 100%;
    background: var(--blue);
    border-left: 10px solid var(--blue);
    transition: 0.25s;
    overflow: hidden;
}
/*.navigation:hover
{
    width: 300px !important; 
}*/
.navigation.active
{
    width: 80px;
}
.navigation ul
{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.navigation ul li
{
    position: relative;
    width: 200%;
    list-style: none;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
}
.navigation ul li:hover
{
    background: var(--white);
}
.navigation ul li a
{
    position: relative;
    display: block;
    width: 100%;
    display: flex;
    text-decoration: none;
    color: var(--white);
}

.navigation ul li:hover a
{
    color: var(--blue);
}
.navigation ul li a .icon
{
    position: relative;
    display: flex;
    min-width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    align-items: center;
    padding: 0 15px;
}
.navigation ul li a .icon ion-icon
{
    font-size: 1.75em;
}
.navigation ul li a .title
{
    position: relative;
    display: block;
    padding: 0 10px;
    height: 60px;
    line-height: 60px;
    text-align: start;
    white-space: nowrap;
}

.main
{
    position: absolute;
    width: calc(100% - 300px);
    left: 300px;
    min-height: 100vh;
    background: var(--white);
    transition: 0.25s;
    height: 100%;
    overflow: hidden;
}
.main.active
{
    width: calc(100% - 80px);
    left: 80px;
}
.topbar
{
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
}
.toggle
{
    position: relative;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.5em;
    cursor: pointer;
    color: var(--white);
}
.search
{
    position: relative;
    width: 400px;
    margin: 0 10px;
}
.search label
{
    position: relative;
    width: 100%;
}
.search label input
{
    width: 100%;
    height: 40px;
    border-radius: 40px;
    padding: 5px 20px;
    padding-left: 35px;
    font-size: 18px;
    outline: none;
    border: 1px solid var(--black2);
}
.search label ion-icon
{
    position: absolute;
    top: 0;
    left: 10px;
    font-size: 1.2em;
}
.cardBox
{
    position: relative;
    width: 100%;
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-gap: 30px;
}
.cardBox .card
{
    position: relative;
    background: var(--white);
    padding: 30px;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
    transition: 0.25s;
}
.cardBox .card .numbers
{
    position: relative;
    font-weight: 500;
    font-size: 2.5em;
    color: var(--blue);
}
.cardBox .card .cardName
{
    color: var(--black2);
    font-size: 1.1em;
    margin-top: 5px;
}
.cardBox .card .iconBx
{
    font-size: 3.5em;
    color: var(--black2);
}
.cardBox .card:hover
{
    background-color: var(--blue);
    scale: 1.1;
    z-index: 999;
}
.cardBox .card:hover .numbers,
.cardBox .card:hover .cardName,
.cardBox .card:hover .iconBx
{
    color: var(--white);
}
.details
{
    position: relative;
    width: 100%;
    display: flex;
    padding: 10px 20px 10px 20px;
    height: 60vh;
    gap: 20px;
}
.details .recentOrders
{
    position: relative;
    display: grid;
    min-height: 500px;
    background: var(--white);
    padding: 20px;
    width: 85%;
    box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
}
.cardHeader
{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 50px;
    height: 30px;
}
.cardHeader h2
{
    font-weight: 600;
    color: var(--blue);
}
.btn
{
    position: relative;
    padding: 5px 10px;
    background: var(--blue);
    text-decoration: none;
    color: var(--white);
    border-radius: 6px;
}
.details .table
{
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}
.details table thead td
{
    font-weight: 600;
    margin-top: 30px;
}
.details .recentOrders table tr
{
    color: var(--black1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    text-align: center;
    height: 50px;
    font-size: 1.25em;
}
.details .recentOrders table tbody tr:hover
{
    background: var(--blue);
    color: var(--white);
}

.callcard{
    display: flex;
    position: relative;
    width: 100%;
    padding: 20px 20px 10px 20px;
    max-height: 40em;
    height: 40vh;
}

.Card {
    display: flex;
    position: relative;
    width: 100%;
    padding: 20px;
    max-height: 40em;
    height: 100%;
    box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    justify-content: space-between;
}

.callcard .transcriptionText{
    position: relative;
    display: flex;
    min-height: 300px;
    background: var(--white);
    padding: 20px;
    overflow-y: overlay;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 50px);
}

.transcription{
    width: 50%;
    height: 100%;
    box-shadow: 0 7px 25px rgb(0 0 0 / 8%);
    padding: 10px;
    border-radius: 10px;
    margin-left: 10px;
}

.callcard .transcriptionText .text {
    width: 48%;
    padding: 5px;
    margin: 5px;
    border-radius: 5px;
    box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
}

.callcard .transcriptionText .left{
    /*background: #5e9afa;*/
    margin-left: 0%;
}

.callcard .transcriptionText .right{
    /*background: #5efa66;*/
    margin-left: 50%;
}

.callcard .transcriptionText .negative{
    background: #fa5e66;
}
.callcard .transcriptionText .neutral{
    background: #5ea7fa;
}
.callcard .transcriptionText .positive{
    background: #5efa8d;
}
.callcard .transcriptionText .skip{
    background: #a8a8a8;
}
.callcard .transcriptionText .speech{
    background: #ffffff;
}

.callcard .transcriptionText .Frases {
    display: flex;
    width: 50%;
    flex-direction: column;
}

table {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;
}
table thead {
    /* head takes the height it requires, 
    and it's not scaled when table is resized */
    flex: 0 0 auto;
    width: calc(100% - 0.9em);
}
table tbody {
    /* body takes all the remaining available space */
    flex: 1 1 auto;
    display: block;
    overflow-y: scroll;
}
table tbody tr {
    width: 100%;
}
table thead, table tbody tr {
    display: table;
    table-layout: fixed;
}

.table-container {
    height: 38em;
}

.transcriptionNames{
    display: flex;
    justify-content: space-between;
    height: 20px;
}
.player{
    width: 50%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    box-shadow: 0 7px 25px rgb(0 0 0 / 8%);
    padding: 10px;
    border-radius: 10px;
    margin-right: 10px;
}
div.player > div:nth-child(2)
{
    width: 70%;
    height: 256px;
}

.playBTN{
    width: 25%;
    height: 125px;
    font-size: 50px;
    border: none;
    color: var(--black1);
    background-color: var(--white);
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 5px;
    cursor: pointer;
    box-shadow: 0 7px 25px rgb(0 0 0 / 8%);
    border-radius: 20px;
    transition: 0.1s;
    max-width: 130px;
    margin-right: 30px;
}

.playBTN:hover{
    background-color: var(--grey);
}

.sumBTN{
    /*width: 25%;*/
    height: 25px;
    font-size: 15px;
    border: none;
    color: var(--white);
    background-color: var(--blue);
    padding: 3px 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 5px;
    cursor: pointer;
    box-shadow: 0 7px 25px rgb(0 0 0 / 8%);
    border-radius: 5px;
    transition: 0.1s;
    max-width: 230px;
    /*margin-right: 30px;*/
}

.deleteBTN{
    background-color: var(--red);
}

.sumBTN:hover{
    background-color: var(--grey);
    color: var(--blue);
    box-shadow: 0 7px 25px rgba(0, 0, 80, 0.30);
}


.metas{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
}

.callMeta{
    width: 400px;
    /*max-height: calc(100% - 256px - 20px);*/
    overflow-y: auto;
    padding: 5px;
}

.metas > span:nth-child(1){
    font-weight: 700;
}

.paginator
{
    height: 100%;
    min-width: 500px;
    display: flex;
    justify-content: space-around;
}
.paginator > button{
    width: 25%;
    max-width: 30px;
    height: 100%;
    font-size: 20px;
    border: none;
    box-shadow: 0 7px 25px rgb(0 0 0 / 8%);
    border-radius: 10px;
    color: var(--black1);
    background-color: var(--white);
    padding: 2px 3px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 5px;
    cursor: pointer;
    transition: 0.1s;
}

.paginator > button:hover{
    background-color: var(--blue);
}
.paginator > select{
    width: 25%;
    height: 100%;
    font-size: 20px;
    border: none;
    box-shadow: 0 7px 25px rgb(0 0 0 / 8%);
    border-radius: 10px;
    color: var(--black1);
    background-color: var(--white);
    padding: 2px 3px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 5px;
    cursor: pointer;
    transition: 0.1s;
}

.paginator > span{
    width: 50%;
    height: 100%;
    font-size: 20px;
    border: none;
    box-shadow: 0 7px 25px rgb(0 0 0 / 8%);
    border-radius: 10px;
    color: var(--black1);
    background-color: var(--white);
    padding: 2px 3px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 5px;
    transition: 0.1s;
}

.FiltersForm{
    position: relative;
    min-height: 300px;
    background: var(--white);
    padding: 20px;
    width: 15%;
    height: 100%;
    box-shadow: 0 7px 25px rgb(0 0 0 / 8%);
    border-radius: 20px;
    display: flex;
    overflow-y: auto;
    flex-direction: column;
    min-width: 350px;
}

.FiltersForm > div.react-datepicker-wrapper{
    height: 15px;
}
.ChartContainer{
    width: 100%;
    height: 90%;
    
}
.chart{
    height:100%;
    width: 100%;
    background-color: var(--white);
    padding: 5px;
    box-shadow: 0 7px 25px rgb(0 0 0 / 8%);
    border-radius: 10px;
    transition: 0.1s;
    overflow-y: hidden;
}

.chartsGrid{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    grid-auto-rows: minmax(100px, 400px);
    padding: 15px;
}
.TagComponent{
    overflow-y: auto;
}
.chartHeader{
    display: flex;
    justify-content: space-between;
}
.chartAction{
    cursor: pointer;
}
.blur{
    filter: blur(4px);
    transition: 0.1s;
}
.hide{
    display: none;
}
.Width100{
    width: 100%;
}
.addChart{
    width: 100%;
    height: 100%;
    font-size: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--grey);
    cursor: pointer;
    transition: 0.2s;
}
.addChart:hover{
    color: var(--black2);
}


#SendFileForm {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    margin: 1rem;
    padding: 1rem;
    border: 1px solid black;
    border-radius: 5px;
    max-width: 400px;
  }
  
  #SendFileForm label {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  #SendFileForm input[type="text"],
  #SendFileForm input[type="number"],
  #SendFileForm input[type="file"],
  #SendFileForm select {
    padding: 0.5rem;
    border: none;
    border-radius: 2px;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  }
  
  #SendFileForm input[type="checkbox"] {
    transform: scale(1.5);
    margin-right: 0.5rem;
  }
  
  #SendFileForm button[type="submit"] {
    padding: 0.5rem 1rem;
    background-color: #333;
    color: white;
    border: none;
    border-radius: 2px;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
    cursor: pointer;
  }
  
  #SendFileForm button[type="submit"]:hover {
    background-color: #555;
  }
  
  #SendFileForm label > div {
    margin-top: 0.2rem;
    font-size: 0.8rem;
    color: #888;
  }

  .dialogquestion{
    width: 500px;
  }

  .padding15 {
    padding: 15px;
  }

  .overflowauto {
    overflow: auto;
  }


  .tagCard{
    margin-bottom: 10px;
    box-shadow: 0 7px 25px rgb(0 0 0 / 8%);
    padding: 15px;
    border-radius: 10px;
  }

  .tagNameInput{
    margin-left: 10px;
    padding: 3px;
    width: 150px;
    margin-right: 10px;
  }

  .tagOptions{
    margin-bottom: 5px;
  }

  .callMetaContainer{
    display: flex;
    max-height: calc(100% - 256px - 20px);
    width: 100%;
    justify-content: space-between;
  }

  .callTags{
    width: auto;
    padding: 5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  .tag1{
    padding: 5px;
    margin: 4px;
    box-shadow: 0 5px 5px rgb(0 0 0 / 20%);
    background-color: var(--blue);
    color: var(--white);
    border-radius: 5px;
  }

  .tag0{
    padding: 5px;
    margin: 4px;
    box-shadow: 0 5px 5px rgb(0 0 0 / 20%);
    background-color: var(--grey);
    color: var(--black1);
    border-radius: 5px;
  }

  .tagContainer{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-content: flex-end;
    align-items: flex-end;
  }